<template>
	<div>
		
			<van-steps :active="step_active" active-icon="success" active-color="#ff976a">
				<van-step>{{translate('method_of_selection')}}</van-step>
				<van-step>{{translate('amount_of_deposit')}}</van-step>
				<van-step>{{translate('check_of_notice')}}</van-step>
			</van-steps>


		<div v-for="(item, index) in currencys" :key="index" 
			style="padding: 15px 10px;
				background: #fff;
				margin: 10px;
				border-radius: 5px;
				display:flex;align-items: center"
			@click="goAmount(item.currency_id)">

			<van-image :src="item.image" width="60" height="60"></van-image>
			<p style="color: #666;padding-left: 15px;font-weight: bold">{{translate('deposit')}}{{ item.name }}</p>
		</div>

		<div class="common_block">
			<p style="color: blue;text-align: center" @click="goHistory">{{translate('gold_entry_record')}}</p>
		</div>

		<div class="common_block">
			<h5 style="margin: 30px 0 10px 0">{{translate('tips_for_bringing_in_money')}}</h5>
			<div class="description" v-html="deposit_tip"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'deposit_index',
		data() {
			return {

				step_active: 0,

				token: "",
				deposit_tip: "",

				currencys: []
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token

				this.$axios({
					method: 'post',
					url: 'cash_deposit/currency', 
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {
						this.currencys = res.data.currencys
						this.deposit_tip = res.data.deposit_tip

						if (res.data.exists) {
							this.$dialog.confirm({
								message: this.translate('the_transfer_receipt_yet'),
								confirmButtonText: this.translate('to_upload')
							}).then(() => {
								this.$router.push('/deposit/history?token=' + this.token)
							})
						}
					}
				})
			}
		},
		methods: {
			goHistory() {
				this.$router.push('/deposit/history?token=' + this.token)
			},
			goAmount(id) {				
				this.$router.push('/deposit/amount?token=' + this.token + '&c=' + id)
			}
		},
	}
</script>
